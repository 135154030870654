var globalData = {};

globalData["index"] = {};
globalData["structures"] = {};
globalData["structureIndex"] = {};
globalData["outlines"] = {};
globalData["outlineIndex"] = {};
globalData["meta"] = {};
globalData["text"] = {};
globalData["timeouts"] = {};


export {globalData};